@import '../../style/variables';


.pagination-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.switch-page {
  background-color: $secondary;
  border: none;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch-page-inactive {
  width: 20px;
}

.switch-page-img {
  background-color: $secondary;
  width: 20px;
}

.switch-page-img:hover {
  animation-name: arrow-right;
  animation-duration: 0.8s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

@keyframes arrow-right {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.button-number-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.pagination-button-number {
  background: transparentize($button, 0.5);
  color: $text;

  width: 30px;
  font-size: 1rem;
  border-radius: 3px;
  margin: 2px;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

}

.pagination-button-number-active {
  color: $button;
  background-color: white;
}

.pagination-button-number:before {
  height: 0%;
  width: 2px;
}