@import "src/style/variables";

.spell-cast-effect {
  padding: 10px;
  background-color: $secondary;
}

.banner {
  width: 100%;
  max-width: 500px;
  height: 170px;

  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;

  border-radius: 10px 10px 0px 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.banner-single {
  max-width: 100%;
  height: 300px;
}

.banner-spell-victory {
  background-image: url("../../assets/img/spell-success.png");
  background-position: 0 50%;
}

.banner-spell-defeat {
  background-image: url("../../assets/img/spell-failed.png");
  background-position: 0 50%;
}

.spell-cast-success {
  text-transform: uppercase;
  color: $success;
}

.spell-cast-failed {
  text-transform: uppercase;
  color: $danger;
}