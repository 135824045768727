@import "src/style/variables";

.conquest-victory {
    color: $success;
}

.conquest-defeat {
    color: $danger;
}

.conquest-recap-title {
    margin-top: 2em;
    border-bottom: 1px solid white;

    display: flex;
    align-items: center;
}