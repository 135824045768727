@import '../../style/variables.scss';

.spell-icon-container {
  position: relative;
}

.spell-icon {
  width: 200px;
  height: 200px;
  border: 3px $text solid;
}

.spell-icon-disabled {
  filter: brightness(68%) saturate(58%) grayscale(100%)
}

.spell-icon-dialog {
  width: 250px;
  height: 250px;
  margin-bottom: 10px;
}

.spell-unlock-text {
  margin: 10px;
  padding: 0px;
}

.spell-icon-top {
  position: absolute;
  left: 5px;
  top: 5px;
}

.spell-icon-right {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 2px;
  background-color: rgba(22, 22, 34, 0.7);
  border-radius: 50px;
}

.spell-icon-bottom {
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 1.4em;
  color: $text;
  background-color: $secondary;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
}

.selected-spell {
  color: $success;
  background-color: rgba(22, 22, 34, 0.5);
  border-radius: 5px;
}