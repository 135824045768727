@import '../../style/variables';

.hamburger {
    width: 50px;
    height: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
}

.hamburger-icon {
    width: 35px;
    height: 3px;
    background-color: white;
    border-radius: 3px;
}

.link-separator {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.hamburger-list {
    z-index: 2;
    background-color: $background;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    padding: 20px;

    font-size: 1.4rem;
    list-style-type: none;
    border-radius: 5px;
}

.invisible {
    display: none;
}

.button-hamburger {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: $text;
    padding: 3px;
}


.toastify-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media screen and (max-width: 575px) {
    .hamburger-list {
        position: fixed;
        width: 100%;
        height: 90%;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    .link-separator {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

.hidden {
    visibility: hidden;
    height: 0;
}

.visible {
    visibility: visible;
    height: 100%;
}

.dropdown {
    a {
        margin-left: 15px;
        color: darken(white, 20);
    }
}