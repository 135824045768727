@import "src/style/variables";

.spell-effect-efficiency-container {
  cursor: pointer;
}

.spell-efficiency-help-text {
  padding: 5px;
  background: $background;
  color: $text;
  text-align: justify;
}