@import "src/style/_variables.scss";

body {
  margin: 0;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  width: 100%;
}

p {
  line-height: 1.2em;
  font-size: 1.05em;
}

.form-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: rgb(49, 49, 59);
}

@for $i from 1 through 10 {
  .m-#{$i} {
    margin: 2px*$i;
  }
}

@for $i from 1 through 10 {
  .mr-#{$i} {
    margin-right: 2px*$i;
  }
}

@for $i from 1 through 10 {
  .ml-#{$i} {
    margin-left: 2px*$i;
  }
}

@for $i from 1 through 10 {
  .mt-#{$i} {
    margin-top: 2px*$i;
  }
}

@for $i from 1 through 20 {
  .mb-#{$i} {
    margin-bottom: 2px*$i;
  }
}

@for $i from 1 through 10 {
  .mx-#{$i} {
    margin-left: 2px*$i;
    margin-right: 2px*$i;
  }
}

@for $i from 1 through 10 {
  .p-#{$i} {
    padding: 2px*$i;
  }
}

@for $i from 1 through 10 {
  .pb-#{$i} {
    padding-bottom: 2px*$i;
  }
}

@for $i from 1 through 10 {
  .px-#{$i} {
    padding-left: 2px*$i;
    padding-right: 2px*$i;
  }
}

@for $i from 1 through 10 {
  .py-#{$i} {
    padding-top: 2px*$i;
    padding-bottom: 2px*$i;
  }
}

@for $i from 1 through 10 {
  .w-#{$i*10} {
    width: 10em*$i;
  }
}

@for $i from 1 through 10 {
  .h-#{$i} {
    height: 5em*$i;
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100vh;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-items-center {
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.justify-flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.strong {
  font-weight: bold;
}

.list-type-none {
  list-style-type: none;
}

.border-rad {
  border-radius: 5px;
}

.radius-100 {
  border-radius: 100%;
}

.border-top {
  border-top: 2px black solid;
}

.right-aligned {
  text-align: right;
}

.text-success {
  color: $success;
}

.text-danger {
  color: $danger;
}

.text-warning {
  color: $warning;
}

.text-info {
  color: $info;
}

.cursor-pointer {
  cursor: pointer;
}

.bold-font {
  font-weight: bolder;
}