@import "src/style/variables";

.chip {
  background-color: darken($button, 10);
  border-radius: 10px;
  padding: 5px 10px;
  margin: 1px 0;

  &.chip-success {
    background-color: darken($success, 10);
    color: $secondary;
  }

  &.chip-warning {
    background-color: darken($warning, 10);
    color: $secondary;
  }

  &.chip-danger {
    background-color: darken($danger, 10);
  }

  &.chip-info {
    background-color: darken($info, 10);
  }
}

.chip-small {
  padding: 3px 5px;
  margin: 0;
  height: 17px;
}

.chip-large {
  font-size: 1em;
}

.chip-selected {
  background-color: $button;

  &.chip-success {
    background-color: $success;
  }

  &.chip-warning {
    background-color: $warning;
  }

  &.chip-danger {
    background-color: $danger;
  }

  &.chip-info {
    background-color: $info;
  }
}