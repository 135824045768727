@import '../../style/variables';

.victory-text {
    color: $validated;
}

.defeat-text {
    color: $refused;
}

.conquest-for-icon {
    position: absolute;
    bottom: 5px ;
    left: -10px;
}