@import '../../style/variables';

.content-pane {

  color: $text;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-pane-content {
  background-color: $background;
  border-radius: 5px;
  padding: 10px;
}