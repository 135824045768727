@import "src/style/variables";

.order-kpi-container {
  margin-top: 1.4em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.order-kpi {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

@media screen and (max-width: $md-breakpoint) {
  .order-kpi-container {
    flex-direction: column;
  }
}