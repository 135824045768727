@import '../../style/variables';


.welcome-logo {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}


.background-landing {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.align-right {
    align-items: flex-end;
    padding-right: 40px;
}

.align-left {
    align-items: flex-start;
    padding-left: 40px;
}

.background-welcome {
    margin-top: 50px;
    background-image: url(../../assets/img/wallpaper.jpg);
}

.background-discover {
    background-image: url(../../assets/img/wallpaper-discover.jpg);
    background-position: 50% 100%;
}

.background-build {
    background-image: url(../../assets/img/wallpaper-town.jpg);
    background-position: 50% 50%;
}

.background-conquest {
    background-image: url(../../assets/img/wallpaper-conquest.jpg);
}

.background-alliance {
    background-image: url(../../assets/img/wallpaper-alliance.jpg);
}

.landing-container {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.landing-container-left {
    align-items: flex-start;
}

.landing-container-right {
    align-items: flex-end;
}

.landing-title {
    width: 100%;
    color: white;
    font-size: 1.4rem;
    background-color: rgba(22, 22, 34, 0.8);
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
}


.landing-content {
    color: white;
    background-color: rgba(22, 22, 34, 0.8);
    padding: 30px;
    font-size: 1.4rem;
    font-weight: lighter;
}


@media (max-width : $md-breakpoint) {

    .landing-title {
        padding: 0px;

        h2 {
            margin: 10px;
        }
    }

    .background-welcome {
        background-position: 50% 50%;

    }

    .background-discover {
        background-position: 50% 100%;
    }

    .background-build {
        background-position: 70% 100%;
    }

    .background-conquest {
        background-position: 50% 100%;

    }

    .background-alliance {
        background-position: 10% 0%;

    }


    .landing-content {
        font-size: 1.1rem;
        font-weight: lighter;
        padding: 10px;
    }
}