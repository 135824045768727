$distance: 20px;

.scroll-to-top-btn {
  position: fixed;
  right: 0;
  bottom: 10px;

  z-index: 100;
  opacity: 100%;

  transition: opacity ease-in-out 300ms;
}

.scroll-to-top-btn-hidden {
  opacity: 0;
  transition: opacity ease-in-out 300ms;
}