@import "src/style/variables";

.market-resource-container {
  background-color: $secondary;
  padding: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.market-resource-selector {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.market-table-container {
  background-color: $background;
}

@media (screen and max-width: $md-breakpoint) {
  .market-resource-selector {
    display: flex;
    flex-direction: column;
  }
}
