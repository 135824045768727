@import "src/style/variables";

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.form-element {
    margin-top: 10px;
    max-width: 30em;
    width: 100%;
}

@media (max-width : $md-breakpoint) {
    .signup-container {
        width: 100%;
        max-width: none;
    }
}