@import "../../style/variables.scss";

.spying-resources-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.resource-spy-tile {
    max-width: 300px;
    margin: 10px;
    padding-right: 5px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $secondary-hover;
}

@media (max-width: $md-breakpoint) {

    .spying-resources-container {

        grid-template-columns: repeat(1, 1fr);
    }
}