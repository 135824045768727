@import "src/style/variables";

.banner-head {

  width: 100%;
  height: 170px;

  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;

  border-radius: 10px 10px 0 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;

  background-position: center;
}

.banner-head-single {
  height: 300px;
}

.banner-head-title {
  background-color: rgba(22, 22, 34, 0.8);
  color: $text;
  max-width: 130px;
  padding: 10px;
  border-radius: 5px;
  line-height: 18px;
}

.banner-head-title-longer {
  max-width: 200px;
}
