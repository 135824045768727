@import '../../style/variables.scss';

.spell-tiles-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  flex-wrap: wrap;
}

.spell-tile {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.spell-tile-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $secondary;

  padding-top: 10px;
}

.compact {
  margin: 10px;
}


.coolDown-timer {
  position: absolute;
  margin-left: 5px;

}