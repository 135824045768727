.citizen-tile-container {
  max-height: 60px;
  min-height: 60px;
  transition: all ease-in-out 0.15s;
  overflow: hidden;


  &.citizen-tile-container-detailed {
    max-height: 600px;
    min-height: 300px;
  }
}