@import "src/style/variables";

.content-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding: 5px;
  background-color: $background;
  color: white;
  border-radius: 5px;
}

.content-box-content {
  background-color: $secondary;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.content-box-container-opacity {
  background-color: $background-opacity;
}

.content-box-content-opacity {
  background-color: transparent;
}