@import "./style/_variables.scss";

html {
    background-color: $secondary;
}

.main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
}

.content-container {
    position: relative;
    padding-top: 90px;
    margin-bottom: 50px;
}

.Toastify__toast {
    background-color: $secondary;
    color: rgb(185, 185, 193);

    font-size: 0.8rem;
    border: 2px $text solid;
}