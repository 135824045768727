@import '../../style/variables';

.action-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.action-card {
    width: 100%;
    max-width: 400px;
    min-height: 450px;
    margin: 3px;
    border: 2px black solid;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: $background;
    color: $text;
}

/*Used for the card who is single in the page*/
.action-card-single {
    max-width: 800px;
}

.action-form {
    margin-left: auto;
    margin-right: auto;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;


    display: flex;
    flex-direction: column;
    justify-content: space-between;


}

.action-description {
    min-height: 35px;
}

.action-input-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.action-roleplay {
    color: $text;
}

.action-input {}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;

}

.action-button {
    font-size: 1rem;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background: rgb(0, 129, 175);
    color: $text;
    border: none;

}

.action-button-enabled {
    background-color: $text;
    color: black;
}

.action-title {
    background-color: rgba(22, 22, 34, 0.8);
    color: $text;
    max-width: 130px;
    padding: 10px;
    border-radius: 5px;
    // line-height: 18px;
}

.action-title-longer {
    max-width: 200px;
}


.action-cost {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.banner {

    width: 100%;
    max-width: 500px;
    height: 170px;

    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;

    border-radius: 10px 10px 0px 0px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.banner-single {
    max-width: 800px;
    height: 300px;
}

.banner-house {
    background-image: url("../../assets/img/house-banner.webp");
}

.banner-farm {
    background-image: url("../../assets/img/farm-banner.webp");

}

.banner-sawmill {
    background-image: url("../../assets/img/sawmill-banner.webp");

}

.banner-ironMine {
    background-image: url("../../assets/img/iron-mine-banner.webp");
    background-position: 0% 50%;
}

.banner-copperMine {
    background-image: url("../../assets/img/copper-mine-banner.webp");
    background-position: 0% 50%;
}

.banner-silverMine {
    background-image: url("../../assets/img/silver-mine-banner.webp");
    background-position: 0% 50%;
}

.banner-goldMine {
    background-image: url("../../assets/img/gold-mine-banner.webp");
    background-position: 0% 50%;
}

.banner-gemstoneMine {
    background-image: url("../../assets/img/gemstone-mine-banner.webp");
    background-position: 0% 50%;
}

.banner-garrison {
    background-image: url("../../assets/img/garrison-banner.jpg");
    background-position: 0% 20%;
}

.banner-wizardSchool {
    background-image: url("../../assets/img/wizard-school-banner.webp");
    background-position: 0% 50%;
}

.banner-spySchool {
    background-image: url("../../assets/img/spy-school-banner.webp");
}

.banner-fortification {
    background-image: url("../../assets/img/fortification-banner.webp");
}

.banner-temple {
    background-image: url("../../assets/img/temple-banner.webp");
}


.banner-exploration {
    background-image: url("../../assets/img/land-exploration.jpg");
}

.banner-villager {
    background-image: url("../../assets/img/villager.webp");
    background-position: 0% 0%;
}

.banner-soldier {
    background-image: url("../../assets/img/soldier.jpg");
    background-position: 0% 20%;
}

.banner-wizard {
    background-image: url("../../assets/img/wizard.jpg");
    background-position: -50% 30%;
}

.banner-spy {
    background-image: url("../../assets/img/spy.jpg");
    background-position: 0% 0%;
}

.banner-conquest {
    background-image: url("../../assets/img/army.jpg");
    background-position: 0% 50%;
}

.banner-spying {
    background-image: url("../../assets/img/spying2.jpg");
    background-position: 0% 50%;
    background-size: cover;
}

.banner-victory {
    background-image: url("../../assets/img/conquest-victory.jpg");
    background-position: 0% 50%;
}

.banner-defeat {
    background-image: url("../../assets/img/conquest-defeat.jpg");
    background-position: 0 50%;
}

.banner-spying-victory {
    background-image: url("../../assets/img/spying-victory.jpg");
    background-position: 0 50%;
}

.banner-spying-defeat {
    background-image: url("../../assets/img/spying-defeat.jpg");
    background-position: 0 50%;
}

@media screen and (max-width: $md-breakpoint) {

    .action-input-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .action-form {
        padding-left: 0;
        padding-right: 0;
    }

    .action-input {
        width: 100%;
    }

    .banner-spying-victory {
        background-position: 50% 100%;
    }

    .banner-spying-defeat {
        background-position: 50% 100%;
    }
}