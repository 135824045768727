.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding: 5px;
    background-color: rgb(22, 22, 34);
    color: white;
    border-radius: 5px;
}

@media (max-width : 350px) {
    .login-container {
        width: 100%;
    }
}