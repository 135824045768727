@import '../../style/variables';

.collapse-box {
    background-color: $background;
    color: $text;
    border-radius: 5px;
    margin-bottom: 10px;
    max-width: 800px;

    margin-left: auto;
    margin-right: auto;

    &-header {


        background-color: $secondary;
        border: 2px $secondary solid;
        border-radius: 3px 3px 0px 0px;
        border-top-color: $background;
        border-bottom-color: $background;
        min-height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;

        &:hover {
            border-color: $primary;
        }
    }

    &-content {
        background-color: $background;
        margin-top: 10px;
        padding: 5px;
        border: 2px $background solid;
    }
}