@import '../../style/variables';

.header-container {
    position: fixed;
    z-index: 10;

    width: 100%;
    color: $text;
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: $background;
    box-shadow: 0 1px 10px 0px $background;
}

.header-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-right: 20px;
    padding-left: 20px;
}

.logo-conquest {
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    opacity: 100%;
    transition: opacity 0.5s ease;
}

.header-item {
    width: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.right {
    justify-content: flex-end;
}

.center {
    justify-content: center;
}

.left {
    justify-content: flex-start;
}

.header-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 500px) {
    .logo-conquest {
        display: none;
    }
}