@import '../../style/variables';

.users-result-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // max-height: 380px;
    padding: 0;
    margin: 10px;
}

.users-results-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $background;
    opacity: 80%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.users-result-list {
    color: $text;
    text-align: baseline;
}

.users-result-list:hover {
    transition-duration: 0.4s;
    color: white;
}

.users-result-list:active {
    color: $button;
}

.users-result-list-active {
    color: $button;
}

.users-result-list-active:hover {
    color: $button;
}

.users-search-form {
    color: $text;
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media screen and (max-width:500px) {
    .users-result-container {
        max-height: 500px;
        flex-wrap: wrap;
    }

}

.MuiLinearProgress-bar {
    transition: none;
}