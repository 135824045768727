@import '../../style/variables';

.conquest-attack-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.exceed {
    color: $danger;
}

.conquest-type-helper {
    padding: 10px;
    background-color: $secondary;
    font-style: italic;
}