@import '../../style/variables';


.free-lands-info-container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    border-top: 2px black solid;
    border-bottom: 2px black solid;
    border-radius: 5px 5px 5px 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background-color: $background;
    color: $text;
}

.free-lands-info {
}