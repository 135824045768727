@import "src/style/variables";

.changelog-item {
  margin-top: 50px;
  margin-bottom: 10px;

  .changelog-item-description {
    color: white;
    font-style: italic;
    background-color: $secondary;
    padding: 10px;
  }


  blockquote {
    color: white;
  }

  ul {
    padding-inline-start: 10px;

    li {
      padding-left: 0;
      list-style-type: none;
      margin-top: 8px;
    }
  }

  .changelog-item-row {
    display: flex;
    flex-direction: row;
  }
}