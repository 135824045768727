@import "src/style/variables";

$chip-height: 40px;
$radius: 5px;
$width: 180px;

.MuiLinearProgress-root {
  border-radius: 0 0 $radius $radius;
}

.element-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: $width;
  height: $chip-height;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;

  border-radius: $radius $radius 0 0;
  font-size: 1em;

  background-color: $secondary;

  img {
    border-radius: $radius 0 0 $radius;
    width: $chip-height;
    height: $chip-height;
  }

  .element-item-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 5px;

    .element-item-value {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-left: 10px;
    }

    .element-item-production {
      /*font-weight: bold;*/
      font-size: 0.8em;

      &.positive {
        /*color: $success;*/
        color: green;
      }

      &.negative {
        color: $danger;
      }
    }
  }

  .element-item-name {
    position: absolute;
    height: 100%;
    background-color: $secondary-hover;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 1em;
    transition: width 0.3s ease-in-out;
    width: 0;
    &.hidden {
      transition: width 0.3s ease-in-out;
      width: 0;
    }

    &.visible {
      width: calc(100% - 40px);
      transition: width 0.2s ease-in-out;
    }
  }

  .element-item-top-name {
    position: absolute;
    top: -5px;
    left: $chip-height + 10px;
    color: $primary;
  }
}

.help-icon {
  transform: scale(0.5);
}