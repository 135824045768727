@import 'src/style/variables';

.tiles-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.tile {
    background-color: $secondary;
    margin: 10px;
    border-radius: 5px;
}

.tile-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 40px;
    padding-right: 5px;
}

.tile-header:hover {
    background-color: $secondary-hover;
}

.tile-header img {
    width: 40px;
    height: 40px;
}

.tile-compact {}


.tile-detailed {
    border-radius: 5px;
    border: 1px $secondary-hover solid;
}

.tile-detailed-header {
    background-color: $secondary-hover;
}

.construction-tile-container {
    max-height: 60px;
    min-height: 60px;
    transition: all ease-in-out 0.15s;
    overflow: hidden;


    &.construction-tile-container-detailed {
        max-height: 100%;
        min-height: 300px;
    }
}

@media screen and (max-width: 600px) {

    .tiles-container {
        grid-template-columns: repeat(1, 1fr);
    }
}