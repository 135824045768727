@import "src/style/variables";

.countdown-chip {
    font-size: 1.4em;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 5px;
    width: 130px;

    border-radius: 5px;
    border: 1px solid $primary;
    margin-right: 10px;
}

@media (max-width: $md-breakpoint) {
    .countdown-chip {
        width: 90px;
        padding: 3px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        font-size: 1.1em;

        svg {
            font-size: 1.2em;
        }
    }
    
}

.countdown-item {
    display: flex;
    align-items: center;
}