@import "src/style/variables";

.title-page {
    text-align: center;
}

.conquest-container {
    color: white;
}

.political-issue {
    padding: 1px 10px;
    overflow-wrap: break-word;
    background-color: $background;
}