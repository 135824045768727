$primary: #75ABBC;
$secondary: #31313B;
$secondary-opacity: rgba(49, 49, 59, 0.7);
$secondary-hover: rgb(59, 59, 78);
$background: rgb(22, 22, 34);
$background-opacity: rgba(22, 22, 34, 0.8);

$info: rgb(0, 129, 175);
$success: #65BD6E;
$warning: #FFA630;
$danger: #AF1B3F;

$text: rgb(185, 185, 193);
$validated: $success;
$refused: $danger;
$button: $info;

$sm-breakpoint: 350px;
$md-breakpoint: 500px;
$lg-breakpoint: 900px;