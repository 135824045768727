@import "../../style/variables";

.resource-value-full {
    color: $validated;
}

.resource-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    text-align: center;
    list-style-type: none;
}

.resource-info-container {
    display: flex;
    flex-direction: column;
}

@media (max-width: $lg-breakpoint) {
    .resource-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: $md-breakpoint) {
    .resource-list {
        grid-template-columns: 1fr;
    }
}