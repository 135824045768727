.ql-toolbar {
    background-color: rgb(185, 185, 193);
}

.ql-toolbar .ql-stroke {
    fill: none;
    stroke: rgb(185, 185, 193);
}

.ql-toolbar .ql-fill {
    fill: #fff;
    stroke: none;
}

.ql-toolbar .ql-picker {
    color: rgb(185, 185, 193);
}

.ql-picker-options {
    color: rgb(49, 49, 59);
}

.ql-editor {
    min-height: 100px !important;
}