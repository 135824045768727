@import "src/style/variables";

$border-definition: $primary solid 1px;
$border-radius: 5px;
$side-padding: 40px;

.versus-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 10px;

  font-size: 1.4em;
  font-weight: bold;
}

.versus-icon {
  z-index: 1;
  color: $text;
  width: 0;
  display: flex;
  align-items: center;

  .icon {
    transform: translateX(-50%);
  }
}

.versus-text {
  z-index: 2;
  color: $danger;
  width: 0;
  display: flex;
  align-items: center;

  div {
    transform: translateX(-50%);
  }
}

.versus-side {
  padding: 15px;
  color: $secondary;

  &.attacker {
    padding-right: $side-padding;

    background-color: $primary;
    color: $secondary;

    border-left: $border-definition;
    border-top: $border-definition;
    border-bottom: $border-definition;

    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  &.defender {
    padding-left: $side-padding;

    background-color: $secondary;
    color: $primary;

    border-right: $border-definition;
    border-top: $border-definition;
    border-bottom: $border-definition;

    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
}