@import '../../style/variables';

$padding-x: 10px;

.footer-container {
    padding: 10px 50px;

    min-height: 100px;
    background-color: $background;
    color: $text;

    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // margin-top: 50px;

    .link-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        a {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.footer-container p {
    margin: 0;
}

@media (max-width: $md-breakpoint) {
    .footer-container {
        flex-direction: column;
    }
}

@media (max-width: $md-breakpoint) {
    .footer-container {
        .link-container {
            flex-direction: column;
        }
    }

}