@import "src/style/variables";


.notification-item-content {
  inline-size: auto;
  overflow-wrap: break-word;
}

.notification-item-subcontent {
  font-size: 0.9em;
  color: $text;
}

.notification-item-detailed {
  color: lighten($text, 10);
}